<template>
  <li
    v-if="canViewHorizontalNavMenuLink(item) && isEnabled(item) && storeIsEnable(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
      'd-flex': !item.icon,
      'pl-25': !item.icon,
    }"
  >
  <feather-icon
      v-bind:class="item.icon ? 'd-none' : 'd-block mt-1 ml-1 text-warning'"
      v-if="item.isFav === true"
      icon="StarIcon"
      class="cursor-pointer fill-current"
      size="16"
      @click="
        () => {
          makeFav(item);
        }
      "
    />
    <feather-icon
      v-bind:class="item.icon ? 'd-none' : 'd-block mt-1 ml-1 text-warning'"
      v-if="item.isFav === false"
      icon="StarIcon"
      class="cursor-pointer"
      size="16"
      @click="
        () => {
          makeFav(item);
        }
      "
    />
    <b-link
      v-bind="linkProps"
      class="dropdown-item d-flex align-items-center"
    >
      <feather-icon
        v-if="item.isFav === true || item.isFav === false "
        :class="item.icon ? 'd-block' : 'd-none'"
        :icon="item.icon || ''"
      />
      <!-- <feather-icon
        v-if="!item.isFav"
        :icon="item.icon || 'CircleIcon'"
        size="5"
      /> -->
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import navMenuItems from '@/navigation/horizontal';
import { BLink } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import useHorizontalNavMenuLink from './useHorizontalNavMenuLink'
import mixinHorizontalNavMenuLink from './mixinHorizontalNavMenuLink'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      navMenuItems,
      favMenu: [],
      UpdatedMenu: [],
      isEnabledAccounts: true,
      isEnabledStores: true
    };
  },
  mixins: [mixinHorizontalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
    // storItem: {
    //   type: Object,
    //   required: true,
    // }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useHorizontalNavMenuLink(props.item)

    const { t } = useI18nUtils()
    const { canViewHorizontalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewHorizontalNavMenuLink,

      // i18n
      t,
    }
  },
  mounted() {
    if (localStorage.accountsEnabled) {
      this.isEnabledAccounts = JSON.parse(localStorage.accountsEnabled)
    }
    if (localStorage.storesEnabled) {
      this.isEnabledStores = JSON.parse(localStorage.storesEnabled)
    }
    this.getFavortes();
  },
  methods: {
    isEnabled(item) {
      if (item.isEnabled && (item.isEnabled === this.isEnabledAccounts)) {
        return true
      }
      if (item.isEnabled === undefined) {
        return true
      }
    },
    storeIsEnable(storItem) {
      if (storItem.isEnabledStore && (storItem.isEnabledStore === this.isEnabledStores)) {
        return true
      }
      if (storItem.isEnabledStore === undefined) {
        return true
      }
    },
    getFavortes() {
    this.favMenu = localStorage.favMenu ? JSON.parse(localStorage.favMenu) : [];
    this.UpdatedMenu = this.navMenuItems
    if (this.favMenu) {
        this.favMenu.forEach((updatedItem) => {
          this.UpdatedMenu.forEach((parent) => {
            if (parent.children) {
            parent.children.forEach((children) => {
              if (updatedItem.routeUrl === children.route) {
                children.isFav = true
              }
            });
            }
            this.navMenuItems = this.UpdatedMenu;
          });
        });
      }
    },
    makeFav(item) {
      item.isFav = !item.isFav;
      this.navMenuItems.forEach((items) => {
        if (items.children) {
          items.children.forEach((x) => {
            const check = this.favMenu ? this.favMenu.some(FavItem => FavItem.routeUrl === x.route) : false;
              if (x.isFav === true) {
              if (this.favMenu.length && check === false) {
                // this.favMenu.push({ routeUrl: x.route, userId: this.profile.userId });
                // localStorage.favMenu = JSON.stringify(this.favMenu)
                this.create({ url: 'FavoriteRoutes', data: { routeUrl: x.route, userId: this.profile.userId } }).then(() => {
                this.get({ url: 'FavoriteRoutes' }).then((data) => {
                  data.forEach((res) => {
                    this.navMenuItems.forEach((parent) => {
                      if (parent.children) {
                        parent.children.forEach((children) => {
                          if (res.routeUrl === children.route) {
                            res.title = children.title;
                          }
                        });
                      }
                    });
                  this.favMenu = data;
                  localStorage.favMenu = JSON.stringify(this.favMenu);
                });
              });
              });
              } else if (!this.favMenu.length && check === false) {
                // this.favMenu.push({ routeUrl: x.route, userId: this.profile.userId });
                // localStorage.favMenu = JSON.stringify(this.favMenu)
                this.create({ url: 'FavoriteRoutes', data: { routeUrl: x.route, userId: this.profile.userId } }).then(() => {
                this.get({ url: 'FavoriteRoutes' }).then((data) => {
                  data.forEach((res) => {
                    this.navMenuItems.forEach((parent) => {
                      if (parent.children) {
                        parent.children.forEach((children) => {
                          if (res.routeUrl === children.route) {
                            res.title = children.title;
                          }
                        });
                      }
                    });
                  this.favMenu = data;
                  localStorage.favMenu = JSON.stringify(this.favMenu);
                });
              });
              });
              }
            } else if (x.isFav === false && x.route === item.route) {
              const indx = this.favMenu.indexOf(item);
              this.favMenu.splice(indx, 1);
              this.create({ url: 'FavoriteRoutes/delete', data: { routeUrl: item.route, userId: this.profile.userId } }).then(() => {
                this.get({ url: 'FavoriteRoutes' }).then((data) => {
                if (data.length > 0) {
                  data.forEach((res) => {
                    this.navMenuItems.forEach((parent) => {
                      if (parent.children) {
                        parent.children.forEach((children) => {
                          if (res.routeUrl === children.route) {
                            res.title = children.title;
                          }
                        });
                      }
                    });
                  this.favMenu = data;
                  localStorage.favMenu = JSON.stringify(this.favMenu);
                });
                } else {
                  this.favMenu = [];
                  localStorage.favMenu = JSON.stringify(this.favMenu);
                }
              });
              })
              // localStorage.favMenu = JSON.stringify(this.favMenu);
            }
          });
        }
      });
    },
  },

}
</script>
