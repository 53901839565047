<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="modifiedNavMenuItems" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import { PROFILE_KEY } from '@/libs/acl/config';
import { reportsRoutes, reportsPermissions } from '@/router/routes/reportsRoutes'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    const attachedchildren = [];
    const profile = JSON.parse(window.localStorage.getItem(PROFILE_KEY) || '{}');
    reportsPermissions.forEach((r) => {
      if (profile.permissions.some(item => r.permissions.includes(item))) {
         attachedchildren.push(r.route);
      }
    })
    const modifiedNavMenuItems = navMenuItems.filter(obj => {
    return obj.title !== 'reports';
    });
    if (profile.isAdmin) {
      modifiedNavMenuItems.push({
    title: 'reports',
    header: 'reports',
    icon: 'FileTextIcon',
    children: reportsRoutes
    })
    }
    if (attachedchildren.length > 0) {
      modifiedNavMenuItems.push({
      title: 'reports',
    header: 'reports',
      icon: 'FileTextIcon',
      children: attachedchildren
    });
    }
    return {
      modifiedNavMenuItems,
      navMenuItems
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
