export default [
  {
    title: 'Home',
    header: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'statistics',
    header: 'statistics',
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'employeesDashboard',
        route: 'employeesDashboard',
        permission: 'viewEmployeesDashboard',
        isFav: false,
      },
    ],
  },
  {
    title: 'publicCodes',
    header: 'publicCodes',
    icon: 'LayersIcon',
    children: [
      {
        title: 'income',
        route: 'income',
        permission: 'income',
        isFav: false,
      },
      {
        title: 'expense',
        route: 'expense',
        permission: 'expense',
        isFav: false,
      },
      {
        title: 'discountTypes',
        route: 'discounts',
        permission: 'discounts',
        isFav: false,
      },
      {
        title: 'banks',
        route: 'banks',
        permission: 'banks',
        isFav: false,
      },
      {
        title: 'PaymentMethods',
        route: 'payment-methods',
        permission: 'paymentMethods',
        isFav: false,
      },
      {
        title: 'safes',
        route: 'safes',
        permission: 'safes',
        isFav: false,
      },
      {
        title: 'suppliers',
        route: 'suppliers',
        permission: 'viewSuppliers',
        isFav: false,
      },
      {
        title: 'areas',
        route: 'areas',
        permission: 'areas',
        isFav: false,
      },
      {
        title: 'drivers',
        route: 'drivers',
        permission: 'drivers',
        isFav: false,
      },
      {
        title: 'buses',
        route: 'buses',
        permission: 'buses',
        isFav: false,
      },
    ],
  },
  {
    title: 'schoolData',
    header: 'schoolData',
    icon: 'ServerIcon',
    children: [
      {
        title: 'nationalties',
        route: 'nationalties',
        permission: 'nationalties',
        isFav: false,
      },
      {
        title: 'studentStatuses',
        route: 'studentStatuses',
        permission: 'viewStudentStatuses',
        isFav: false,
      },
      {
        title: 'serviceCategories',
        route: 'serviceCategories',
        permission: 'serviceCategories',
        isFav: false,
      },
      {
        title: 'services',
        route: 'services',
        permission: 'services',
        isFav: false,
      },
      {
        title: 'stages',
        route: 'stages',
        permission: 'stages',
        isFav: false,
      },
      {
        title: 'rows',
        route: 'rows',
        permission: 'rows',
        isFav: false,
      },
      {
        title: 'classRooms',
        route: 'classRooms',
        permission: 'classRooms',
        isFav: false,
      },
      {
        title: 'guardians-list',
        route: 'guardians-list',
        permission: 'guardians',
        isFav: false,
      },
      {
        title: 'students-list',
        route: 'students-list',
        permission: 'students',
        isFav: false,
      }
    ],
  },
  {
    title: 'items',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'units',
        route: 'units',
        permission: 'viewUnits',
        isFav: false
      },
      {
        title: 'itemsCategories',
        route: 'items-category',
        permission: 'viewItemCategories',
        isFav: false,
      },
      {
        title: 'itemsList',
        route: 'items',
        permission: 'viewItems',
        isFav: false,
      },
    ],
  },
  {
    title: 'humanResources',
    header: 'humanResources',
    icon: 'PocketIcon',
    children: [
      {
        title: 'employees',
        route: 'employees-list',
        permission: 'viewEmployees',
        isFav: false,
      },
      {
        title: 'maritalStatus',
        route: 'marital-status',
        permission: 'viewMaritalStatus',
        isFav: false,
      },
      {
        title: 'salaryItems',
        route: 'salary-items',
        permission: 'viewBenefitAndDeduction',
        isFav: false,
      },
      {
        title: 'qualification',
        route: 'qualification',
        permission: 'viewQualification',
        isFav: false,
      },
      {
        title: 'sections',
        route: 'sections',
        permission: 'viewSections',
        isFav: false,
      },
      {
        title: 'jopTitle',
        route: 'jop-title',
        permission: 'viewJopTitle',
        isFav: false,
      },
      {
        title: 'mangement',
        route: 'mangement',
        permission: 'viewMangement',
        isFav: false,
      },
      {
        title: 'religion',
        route: 'religion',
        permission: 'viewReligion',
        isFav: false,
      },
      {
        title: 'typeVacations',
        route: 'typeVacations',
        permission: 'viewTypeVacations',
        isFav: false,
      },
    ],
  },
  {
    title: 'employeeMovement',
    header: 'employeeMovement',
    icon: 'FigmaIcon',
    children: [
      {
        title: 'vacationRequest',
        route: 'vacationRequest',
        permission: 'viewVacationRequest',
        isFav: false,
      },
      {
        title: 'benefitsAndDeductions',
        route: 'benefitsAndDeductions',
        permission: 'viewBenefitAndDeduction',
        isFav: false,
      },
      {
        title: 'advancedPayments',
        route: 'employeeAdvancePayment',
        permission: 'viewAdvancePayment',
        isFav: false,
      },
      {
        title: 'calculateSalary',
        route: 'calculateSalary',
        permission: 'viewCalculateSalary',
        isFav: false,
      },
      {
        title: 'payrollManager',
        route: 'salary',
        permission: 'viewCalculateSalary',
        isFav: false,
      }
    ]
  },
  {
    title: 'purchases',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'previewPurchases',
        route: 'purchInvoice-list',
        permission: 'viewPurchTransactions',
        isFav: false,
      },
      // {
      //   title: 'purchOrder',
      //   route: 'purchOrder',
      //   permission: 'addPurchOrders',
      //   isFav: false,
      // },
      {
        title: 'purch',
        route: 'purchInvoice',
        permission: 'addPurchTransactions',
        isFav: false,
      },
      // {
      //   title: 'returnPurchases',
      //   route: 'return-purch-new',
      //   permission: 'addReturnPurchTransactions',
      //   isFav: false,
      // }
      {
        title: 'warehouses',
        route: 'warehouses',
        permission: 'viewStores',
        isFav: false,
      },
    ],
  },
  {
    title: 'invoices',
    header: 'invoices',
    icon: 'ArchiveIcon',
    children: [
      // {
      //   title: 'invoices',
      //   route: 'invoices',
      //   permission: '',
      // },
      {
        title: 'invoiceList',
        route: 'invoices',
        permission: 'viewSalesTransactions',
        isFav: false,
      },
      {
        title: 'salesPoints',
        route: 'pos-invoice',
        permission: 'addSalesTransactions',
        isFav: false,
      },
      {
        title: 'returnSales',
        route: 'return-invoice',
        permission: 'addReturnSalesTransactions',
        isFav: false,
      },
      {
        title: 'deservedFees',
        route: 'deservedFees',
        permission: 'viewDeservedFees',
        isFav: false,
      }
    ],
  },
  {
    title: 'inventory-wharehous',
    icon: 'BoxIcon',
    children: [
      {
        title: 'inventory',
        route: 'inventory',
        permission: 'viewStocktaking',
        isFav: false,
      },
      {
        title: 'shortageAdjustment',
        route: 'shortage-adjustment',
        permission: 'viewShortageAdjustment',
        isFav: false,
      },
      {
        title: 'overflowAdjustment',
        route: 'overflow-adjustment',
        permission: 'viewOverflowAdjustment',
        isFav: false,
      },
    ],
  },
  {
    title: 'stores',
    icon: 'TriangleIcon',
    children: [
      {
        title: 'orders',
        route: 'orders',
        permission: 'viewStockCategoryTransactions',
        isFav: false,
      },
      {
        title: 'exchangeOrder',
        route: 'exchange-order-new',
        permission: 'viewExchangeOrders',
        isFav: false,
        isForGeneral: true
      },
      {
        title: 'additionOrder',
        route: 'addition-order-new',
        permission: 'viewAdditionOrders',
        isFav: false,
      },
      {
        title: 'storageTransfer',
        route: 'storage-transfer',
        permission: 'viewStorageTransfer',
        isFav: false,
      },
      {
        title: 'damageAdjustment',
        route: 'damage-adjustment',
        permission: 'viewDamageAdjustments',
        isFav: false,
      },
    ],
  },
  {
    title: 'assets',
    header: 'assets',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Collection Transaction',
        route: 'collection-transactions',
        permission: 'collectionVouchers',
        isFav: false,
      },
      {
        title: 'paymentTransaction',
        route: 'payment-transactions',
        permission: 'paymentVouchers',
        isFav: false,
      },
      {
        title: 'studentDiscounts',
        route: 'studentDiscounts',
        permission: 'studentDiscounts',
        isFav: false,
      },
      {
        title: 'expenseTransaction',
        route: 'expense-transactions',
        permission: 'expenseTransaction',
        isFav: false,
      },
      {
        title: 'Income Transaction',
        route: 'income-transactions',
        permission: 'incomeTransaction',
        isFav: false,
      },
      // {
      //   title: 'bankDeposit',
      //   route: 'bankDeposit-transactions',
      //   permission: 'bankDeposit',
      //   // isFav: false,
      // }
    ],
  },
  {
    title: 'accounts',
    header: 'accounts',
    icon: 'TrelloIcon',
    children: [
      {
        title: 'chartOfAccount',
        route: 'chartOfAccount',
        permission: 'viewAccounts',
        isFav: false,
      },
      {
        title: 'costCenter',
        route: 'costCenter',
        permission: 'viewCostCenters',
        isFav: false,
      },
      {
        title: 'vouchers',
        route: 'vouchers',
        permission: 'vouchers',
        isFav: false,
      },
      {
        title: 'postVouchers',
        route: 'post-vouchers',
        permission: 'gLPost',
        isFav: false,
      },
    ],
  },

  {
    title: 'procedures',
    header: 'procedures',
    icon: 'SlidersIcon',
    children: [
      {
        title: 'messages',
        route: 'messages',
        permission: 'messages',
        isFav: false,
      },
      {
        title: 'discountsCollection',
        route: 'discountsCollection',
        permission: 'addDiscountCollection',
        isFav: false,
      },
      {
        title: 'studentInvoices',
        route: 'studentInvoices',
        permission: 'studentInvoices',
        isFav: false,
      },
      {
        title: 'deservedFeesCollection',
        route: 'deservedFeesCollection',
        permission: 'addDeservedFees',
        isFav: false,
      },
      {
        title: 'busAccommodation',
        route: 'busAccommodation',
        permission: 'busAccommodation',
        isFav: false,
      },
      {
        title: 'studentSuccess',
        route: 'studentSuccess',
        permission: 'createStudentSuccess',
        isFav: false,
      },
      {
        title: 'temporaryStudents',
        route: 'temporaryStudents',
        permission: 'viewRegistrationRequests',
        isFav: false,
      }
    ],
  },

  {
    title: 'Settings',
    icon: 'SettingsIcon',
    header: 'Settings',
    children: [
      {
        title: 'school-setting',
        route: 'school-setting',
        permission: 'viewSchool',
        isFav: false,
      },
      // {
      //   title: 'teamWork',
      //   route: 'accessGroups-list',
      //   // isFav: false,
      // },
      {
        title: 'Branches',
        route: 'branches-list',
        permission: 'viewBranches',
        isFav: false,
      },
      {
        title: 'users',
        route: 'users',
        permission: 'users',
        isFav: false,
      },
      {
        title: 'messages-settings',
        route: 'messages-settings',
        permission: 'messages-settings',
        isFav: false,
      },
    ],
  },
];
