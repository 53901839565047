<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span>
            <b-img
              :src="imgUrl"
              alt="logo"
              style="width: 30%;"
            />
          </span>
          <!-- <h2 class="brand-text mb-0">
            {{ appName }}
          </h2> -->
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import store from '@/store/index';

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage, logoTwo } = $themeConfig.app
    return {
      appName,
      appLogoImage,
      logoTwo
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark' || store.state.appConfig.layout.skin === 'semi-dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logoTwo = require('@/assets/images/menuLogoDark.png');
        return this.logoTwo;
      }
      if (store.state.appConfig.layout.skin === 'light' || store.state.appConfig.layout.skin === 'bordered') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logoTwo = require('@/assets/images/LOGO FILES/login.png');
        return this.logoTwo;
      }
      return this.logoTwo;
    },
  },
}
</script>

<style>

</style>
